import React from "react"
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cover from "../components/cover";
import MouseScroll from "../components/layout/MouseScroll";
import {graphql} from "gatsby";
import { useIntl, Link } from "gatsby-plugin-intl"

import worldDots from "../images/dot-map-background.webp";
import apple from "../images/technologies/apple.svg";
import android from "../images/technologies/android.svg";
import react from "../images/technologies/react.svg";
import express from "../images/technologies/express.svg";
import laravel from "../images/technologies/laravel.svg";
import nodejs from "../images/technologies/nodejs.svg";
import atlassian from "../images/technologies/atlassian.svg";
import shopify from "../images/technologies/shopify2.svg";
import aws from "../images/technologies/aws.svg";
import stripe from "../images/technologies/stripe.svg";
import digitalOcean from "../images/technologies/digitalocean.svg";
import html5 from "../images/technologies/html-5.svg";
import adobeXd from "../images/technologies/adobe-xd.svg";
import adobeIllustrator from "../images/technologies/adobe-illustrator.svg";
import sketch from "../images/technologies/sketch.svg";
import graphqlImg from "../images/technologies/graphql.svg";
import gatsbyImg from "../images/technologies/gatsby.svg";
import javascript from "../images/technologies/javascript.svg";


const IndexPage = ({data}) => {
    const intl = useIntl();
    return (
    <>
        <SEO title="Qobalt Technologies"
             lang={intl.locale}
             description={intl.formatMessage({id:"description"})} />
        <Layout>
            <Cover style={{background: "linear-gradient(180deg, rgba(14,28,73,1) 20%, rgba(12,55,245,1) 100%)"}}>
                <span className="cover-background-text">
                    <div className="cover-background-text-container">
                        <h1>apps</h1>
                        <h1>web</h1>
                        <h1>e-commerce</h1>
                    </div>
                </span>
                <div className="cover-content">
                    <h1 className="heading1">Qobalt Technologies</h1>
                    <h1 className="heading2">{intl.formatMessage({id:"welcomePage.heroDescription"})}</h1>
                    <p>
                        <Link to="/contact" className="btn btn-light btn-hero inverted animated fade">
                            {intl.formatMessage({id:"contactButton"})} <span role="img" aria-label="feeling-cool">😎</span>
                        </Link>
                    </p>
                </div>
                <MouseScroll className="scroll-indicator"/>
            </Cover>
            <HomeContainer>
                <section className="home--header" style={{backgroundImage: `url(${worldDots})`}}>
                    <div className="container-fluid">
                        <p>{intl.formatMessage({id:"welcomePage.description"})}</p>
                    </div>
                </section>
                <section className="section section--grid">
                    <div className="container-fluid">
                        <div className="grid--home">
                            <div className="grid__item grid--size grid__item--header pre--inview inview">
                                <h2>{intl.formatMessage({id:"ourWork"})}</h2>
                            </div>
                            <div style={{background: `transparent`}}
                                 className="grid__item grid--size grid__item--left grid__item--work pre--inview inview">
                                <a href="https://www.hausly.mx" style={{backgroundImage: `url(${data.hauslyImage.childImageSharp.fluid.src})`}} className="grid__item__link">
                                    <h3>Hausly /</h3>
                                    <h4 className="black-background">{intl.formatMessage({id:"workType.saas"})}</h4>
                                </a>
                            </div>
                            <div style={{background: `transparent`}}
                                 className="grid__item grid--size grid__item--left grid__item--work pre--inview inview">
                                <a href="https://www.mrplayeras.com" style={{backgroundImage: `url(${data.mrplayerasImage.childImageSharp.fluid.src})`}} className="grid__item__link">
                                    <h3>Mr. Playeras /</h3>
                                    <h4 className="black-background">{intl.formatMessage({id:"workType.ecommerce"})}</h4>
                                </a>
                            </div>
                            <div style={{background: `transparent`}}
                                 className="grid__item grid--size grid__item--left grid__item--work pre--inview inview">
                                <a href="https://www.happyface.zone" style={{backgroundImage: `url(${data.happyFaceImage.childImageSharp.fluid.src})`}} className="grid__item__link">
                                    <h3>Happy Face /</h3>
                                    <h4 className="black-background">{intl.formatMessage({id:"workType.ecommerce"})}</h4>
                                </a>
                            </div>
                            <div style={{background: `transparent`}}
                                className="grid__item grid--size grid__item--left grid__item--work grid__item--work-white pre--inview inview">
                                <a href="/#" style={{backgroundImage: `url(${data.oktokSeafoodsImage.childImageSharp.fluid.src})`}} className="grid__item__link">
                                    <h3>Oktok Seafoods /</h3>
                                    <h4>{intl.formatMessage({id:"workType.websiteRedesign"})}</h4>
                                </a>
                            </div>
                            <div style={{background: `transparent`}}
                                 className="grid__item grid--size grid__item--left grid__item--work pre--inview inview">
                                <a href="/#" style={{backgroundImage: `url(${data.nanoImage.childImageSharp.fluid.src})`}} className="grid__item__link">
                                    <h3>Nano /</h3>
                                    <h4 className="black-background">{intl.formatMessage({id:"workType.mobileApp"})}</h4>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section  section--services">
                    <div className="container-fluid">
                        <div className="content  content--text">
                            <h1 className="section__title pre--inview text__animation--slow inview"
                                data-text="Services">{intl.formatMessage({id:"services.title"})}</h1>
                            <p className="section__description">
                                {intl.formatMessage({id:"welcomePage.services.description"},
                                    {
                                        b: (...chunks) => <span className="text--highlight">
                                            {chunks}</span>
                                    }
                                )}
                            </p>
                            <div className="services--list">
                                <div className="col  col--3">
                                    <h2 className="list--header">{intl.formatMessage({id:"strategy"})}</h2>
                                    <ul className="list">
                                        <li className="list--item">
                                            <Link to={`/product-strategy`}>
                                                <h3>{intl.formatMessage({id:"services.productStrategy.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/business-consulting">
                                                <h3>{intl.formatMessage({id:"services.businessConsulting.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <h3>{intl.formatMessage({id:"services.ideation.title"})}</h3>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/planning">
                                                <h3>{intl.formatMessage({id:"services.planning.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <h3>{intl.formatMessage({id:"services.optimization.title"})}</h3>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col  col--3">
                                    <h2 className="list--header">{intl.formatMessage({id:"design"})}</h2>
                                    <ul className="list">
                                        <li className="list--item">
                                            <Link to="/design-thinking">
                                                <h3>{intl.formatMessage({id:"services.designThinking.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/user-research">
                                                <h3>{intl.formatMessage({id:"services.userResearch.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/user-experience-design">
                                                <h3>{intl.formatMessage({id:"services.userExperienceDesign.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/content-strategy">
                                                <h3>{intl.formatMessage({id:"services.contentStrategy.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/visual-design">
                                                <h3>{intl.formatMessage({id:"services.visualDesign.title"})}</h3>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col  col--3">
                                    <h2 className="list--header">{intl.formatMessage({id:"technology"})}</h2>
                                    <ul className="list">
                                        <li className="list--item">
                                            <h3>{intl.formatMessage({id:"services.webDevelopment.title"})}</h3>
                                        </li>
                                        <li className="list--item">
                                            <h3>{intl.formatMessage({id:"services.iphoneDevelopment.title"})}</h3>
                                        </li>
                                        <li className="list--item">
                                            <h3>{intl.formatMessage({id:"services.androidDevelopment.title"})}</h3>
                                        </li>
                                        <li className="list--item">
                                            <h3>E-commerce, CRM, ERP</h3>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/mvp-building">
                                                <h3>{intl.formatMessage({id:"services.mvpBuilding.title"})}</h3>
                                            </Link>
                                        </li>
                                        <li className="list--item">
                                            <Link to="/rapid-prototyping">
                                                <h3>{intl.formatMessage({id:"services.rapidPrototyping.title"})}</h3>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section section--tech-stack">
                    <div className="container-fluid">
                        <div className="content  content--text">
                            <h3 style={{textAlign: "center", marginBottom: "4.5rem", marginTop: "2.5rem"}}>{intl.formatMessage({id:"welcomePage.technologyStackTitle"})}</h3>
                            <div className="tech-stack-container">
                                <div className="img-wrap">
                                    <img src={apple} alt="iPhone app development services" />
                                </div>
                                <div className="img-wrap">
                                    <img src={android} alt="Android app development services" />
                                </div>
                                <div className="img-wrap">
                                    <img src={react} alt="ReactJS web development services" />
                                </div>
                                <div className="img-wrap">
                                    <img src={gatsbyImg} alt="Gatsby development services" />
                                </div>
                                <div className="img-wrap">
                                    <img src={javascript} alt="Javascript development services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={graphqlImg} alt="GraphQL development services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={shopify} alt="Shopify e-commerce development services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={stripe} alt="Stripe services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={html5} alt="HTML5 services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={nodejs} alt="NodeJS web Development services" />
                                </div>
                                <div className="img-wrap">
                                    <img src={express} alt="Express web development services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={laravel} alt="Laravel web development services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={atlassian} alt="Atlassian project management services "  />
                                </div>
                                <div className="img-wrap">
                                    <img src={aws} alt="Amazon web services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={digitalOcean} alt="DigitalOcean services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={sketch} alt="Sketch services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={adobeXd} alt="Adobe XD services"  />
                                </div>
                                <div className="img-wrap">
                                    <img src={adobeIllustrator} alt="Adobe Illustrator services"  />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{background: "rgb(245, 247, 255)"}} className="section section--contact">
                    <div className="container-fluid">
                        <div className="content  content--text">
                            <h1 className="section__title pre--inview text__animation--slow inview"
                                data-text="Services">{intl.formatMessage({id:"process.title"})}</h1>
                            <p className="section__description">
                                {intl.formatMessage({id:"process.description"})}
                            </p>

                            <div className="process--container">
                                <div className="row">
                                    <div className="col col--3">
                                        <img alt={intl.formatMessage({id:"strategy"})} src={data.process1.childImageSharp.fluid.src} />
                                        <span className="process--title">1. {intl.formatMessage({id:"strategy"})}</span>
                                    </div>
                                    <div className="col col--3">
                                        <img alt={intl.formatMessage({id:"design"})} src={data.process2.childImageSharp.fluid.src} />
                                        <span className="process--title">2. {intl.formatMessage({id:"design"})}</span>
                                    </div>
                                    <div className="col col--3">
                                        <img alt={intl.formatMessage({id:"development"})} src={data.process3.childImageSharp.fluid.src} />
                                        <span className="process--title">3. {intl.formatMessage({id:"development"})}</span>
                                    </div>
                                </div>
                            </div>

                            <p className="section__text">
                                {intl.formatMessage({id:"process.subDescription1"})}
                            </p>
                            <p className="section__text">
                                {intl.formatMessage({id:"process.subDescription2"})}
                            </p>
                        </div>
                    </div>
                </section>
            </HomeContainer>
        </Layout>
    </>
)};
const HomeContainer = styled.div`
  background: #ffffff;
  .section--services {
    padding-bottom: 1rem;
    a {
      color: inherit;
      text-decoration: none;
      position: relative;
    }
    a::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 70%;
      left: -0.1px;
      right: -0.1px;
      bottom: 0;
      background-color: rgba(12,55,245,0.15);
      transition: top 0.1s ease-in-out, background-color 0.3s ease-in-out;
    }
    a:hover::after {
      top: 0;
      background-color: rgba(12,55,245,0.15);
      transition: top 0.1s ease-in-out, background-color 0.3s ease-in-out;
    }
    .section__description {
      .text--highlight h4 {
        display: inline-block;
        font-size: 1.62671rem;
        line-height: 2.2rem;
        font-weight: 400;
      }
    }
    .list--item {
      h3 {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        font-family: var(--secondary-font);
      }
    }
  }
  .section.section--tech-stack {
    padding-top: 0;
  }
  .tech-stack-container {
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .tech-stack--description {
      visibility: hidden;
    }
    .img-wrap {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0px;
        flex: 1 0 0;
        min-width: 50%;
        margin-bottom: 40px;
        text-align: center;
    }
    img {
      max-width: 85px;
      max-height: 60px;
    }
    @media (min-width: 768px){
        .img-wrap {
            min-width: 25%;
            margin-bottom: 80px;
        }
    }
    @media (min-width: 576px) {
        .img-wrap {
            min-width: 33.3333%;
            margin-bottom: 60px;
        }
    }
  }
  .process--container {
    margin: 5rem 0;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.35rem;
    }
    text-align: center;
    .process--title {
      font-weight: 600;
      font-family: var(--secondary-font);
      font-size: 1.375rem;
    }
    @media(max-width: 768px) {
      margin: 2rem 0;
    }
  }
`;

export default IndexPage;
export const query = graphql`
    query {
        hauslyImage: file(
            relativePath: { regex: "/case-studies/hausly.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 800,
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        mrplayerasImage: file(
            relativePath: { regex: "/case-studies/mrplayeras.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 650,
                    quality: 95
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        happyFaceImage: file(
            relativePath: { regex: "/case-studies/happyface.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 650,
                    quality: 95
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        oktokSeafoodsImage: file(
            relativePath: { regex: "/case-studies/oktok-seafoods.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 650,
                    quality: 95
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        nanoImage: file(
            relativePath: { regex: "/case-studies/nano.jpg/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 650,
                    quality: 95
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        aws: file(
            relativePath: { regex: "/technologies/aws.png/" }
        ) {
            childImageSharp {
                fluid(
                    maxWidth: 120,
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        process1: file(
            relativePath: { regex: "/process/proc1.png/" }
        ) {
            childImageSharp {
                fluid(
                    quality: 98
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        process2: file(
            relativePath: { regex: "/process/proc2.png/" }
        ) {
            childImageSharp {
                fluid(
                    quality: 98
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        process3: file(
            relativePath: { regex: "/process/proc3.png/" }
        ) {
            childImageSharp {
                fluid(
                    quality: 98
                ) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }    
`;
